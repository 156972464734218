import mock from '../mock'

const data = {
    semesters: [
      {
        key: 1,
        kd_semester: "20213",
        nama_semester: "Genap 2021/2022"
      },
      {
        key: 2,
        kd_semester: "20211",
        nama_semester: "Ganjil 2021/2022"
      },
      {
        key: 3,
        kd_semester: "20203",
        nama_semester: "Genap 2020/2021"
      },
      {
        key: 4,
        kd_semester: "20201",
        nama_semester: "Ganjil 2020/2021"
      },
      {
        key: 5,
        kd_semester: "20193",
        nama_semester: "Genap 2019/2020"
      },
      {
        key: 6,
        kd_semester: "20191",
        nama_semester: "Ganjil 2019/2020"
      },
      {
        key: 7,
        kd_semester: "20183",
        nama_semester: "Genap 2018/2019"
      },
      {
        key: 8,
        kd_semester: "20181",
        nama_semester: "Ganjil 2018/2019"
      },
      {
        key: 9,
        kd_semester: "20173",
        nama_semester: "Genap 2017/2018"
      },
      {
        key: 10,
        kd_semester: "20171",
        nama_semester: "Ganjil 2017/2018"
      },
      {
        key: 11,
        kd_semester: "20163",
        nama_semester: "Genap 2016/2017"
      }
    ]
}

// GET ALL DATA
mock.onGet('/api/pengajar/list/all-semester').reply(200, data.semesters)